import { post, get } from '@/utils/http'

export function getMatchData (params) {
  return post({ url: '/service-insight/finance_stock/get_match_data', params })
}
export function addDetail (params) {
  return get({ url: '/service-insight/finance_stock/add_detail', params })
}
export function getAddData (params) {
  return post({ url: '/service-insight/finance_stock/get_add_data', params })
}
export function getStartData (params) {
  return post({ url: '/service-insight/finance_stock/get_start_data', params })
}
export function matchDetail (params) {
  return get({ url: '/service-insight/finance_stock/match_detail', params })
}
export function startDetail (params) {
  return get({ url: '/service-insight/finance_stock/start_detail', params })
}