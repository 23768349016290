<template>
<div class="stock">
    <div class="topPart">
        <div class="form-item">
            <span class="title">证券类型</span>
            <RadioGroup v-model="type" @on-change="changeType">
                <Radio label="startIssue">
                    <span>首发</span>
                </Radio>
                <!-- <Radio label="addIssue" class="addIssue">
                    <span>增发</span>
                </Radio>
                <Radio label="rightsIssue">
                    <span>配股</span>
                </Radio> -->
            </RadioGroup>
        </div>
        <div class="form-item">
            <span class="title">证券简称</span>
            <Input v-model="name" placeholder="模糊搜索" style="width: 300px; margin-right: 60px" />
            <span class="title">证券代码</span>
            <Input v-model="code" placeholder="模糊搜索" style="width: 300px" />
        </div>
        <div class="form-item">
            <span class="title">发行日期</span>
            <DatePicker type="date" format="yyyy-MM-dd" v-model="issued.timeStart" @on-change="getTimeStartfx" placeholder="开始时间" style="width: 140px; margin-right: 5px"></DatePicker>至
            <DatePicker type="date" format="yyyy-MM-dd" v-model="issued.timeEnd" @on-change="getTimeEndfx" placeholder="结束时间" style="width: 140px; margin: 0 50px 0 5px"></DatePicker>
            <span class="title">上市日期</span>
            <DatePicker :type="'date'" format="yyyy-MM-dd" @on-change="getTimeStart" v-model="goPublic.timeStart" placeholder="开始时间" style="width: 140px; margin-right: 5px"></DatePicker>至
            <DatePicker :type="'date'" @on-change="getTimeEnd" v-model="goPublic.timeEnd" format="yyyy-MM-dd" placeholder="结束时间" style="width: 140px; margin: 0 50px 0 5px"></DatePicker>
        </div>
        <Button type="info" class="searchBtnLog" data-id="1" @click="search" style="width: 120px; display: inline-block; margin: 0 30px 0px 400px">查询</Button>
        <Button type="warning" ghost @click="clear" style="width: 120px; display: inline-block; margin: 0 0 0 0">重置</Button>
    </div>
    <div class="middlePart">
        <span>已选条件：</span>
        <Tag type="border" v-if="type" size="large" closable>
            证券类型:
            <span class="tabContent">{{ typeFilter(type) }}</span>
        </Tag>
        <Tag type="border" v-if="name" size="large" @on-close="name = ''" closable>
            证券简称:
            <span class="tabContent">{{ name }}</span>
        </Tag>
        <Tag type="border" v-if="code" size="large" @on-close="code = ''" closable>
            证券代码:
            <span class="tabContent">{{ code }}</span>
        </Tag>
        <Tag type="border" v-if="issued.timeStart && issued.timeEnd" size="large" @on-close="issued.timeStart = issued.timeEnd = ''" closable>
            发行日期:
            <span class="tabContent">{{ issued.timeStart }}至{{ issued.timeEnd }}</span>
        </Tag>
        <Tag type="border" v-if="goPublic.timeStart && goPublic.timeEnd" size="large" @on-close="goPublic.timeStart = goPublic.timeEnd = ''" closable>
            上市日期:
            <span class="tabContent">{{ goPublic.timeStart }}至{{ goPublic.timeEnd }}</span>
        </Tag>
    </div>
    <div class="bottomPart">
        <Table border tooltip-theme="light" :loading="dataLoading" :columns="stockColumns" :data="stockTable">
            <template slot-scope="{ row }" slot="action">
                <Button type="primary" size="small" style="margin-right: 5px" @click.stop="showDetail(row)">详情</Button>
            </template>
        </Table>
        <div style="text-align: right; padding: 20px">
            <Page :total="totalNum" @on-change="changePage" show-elevator />
        </div>
    </div>
</div>
</template>

<script>
import {
    mapState,
    mapMutations
} from "vuex";
import {
    getStartData,
    getAddData,
    getMatchData,
    addDetail,
    matchDetail,
    startDetail,
} from "@/api/financial/stock";
import {
    checkDate
} from "@/utils/index";
import dataReports from "@/mixins/dataReports";
// , getMatchData, addDetail, getAddData, matchDetail, startDetail
export default {
    name: "stock",
    mixins: [dataReports],
    data() {
        return {
            type: "startIssue",
            addIssueType: 0,
            name: "",
            code: "",
            issued: {
                timeStart: "",
                timeEnd: "",
            },
            totalNum: 0,
            current: 0,
            goPublic: {
                timeStart: "",
                timeEnd: "",
            },
            dataLoading: false,
            stockTable: [],
            stockColumns3: [{
                    title: "证券代码",
                    align: "center",
                    ellipsis: true,
                    tooltip: true,

                    key: "zqdm",
                },
                {
                    title: "证券简称",
                    align: "center",
                    ellipsis: true,
                    tooltip: true,
                    key: "zqjc",
                },
                {
                    title: "股票类别",
                    ellipsis: true,
                    tooltip: true,

                    align: "center",
                    key: "gplb",
                },
                {
                    title: "配股比例",
                    ellipsis: true,
                    tooltip: true,

                    align: "center",
                    key: "pgbl",
                    render: (h, params) => {
                        return h(
                            "span",
                            params.row.pgbl ? Number(params.row.pgbl).toFixed(2) : params.row.pgbl
                        );
                    },
                },
                {
                    title: "配股价格/元",
                    ellipsis: true,
                    tooltip: true,

                    align: "center",
                    key: "pgjg",
                    render: (h, params) => {
                        return h(
                            "span",
                            params.row.pgjg ? Number(params.row.pgjg).toFixed(2) : params.row.pgjg
                        );
                    },
                },
                {
                    title: "实际配股数量",
                    ellipsis: true,
                    tooltip: true,

                    align: "center",
                    key: "sjpgsl",
                    render: (h, params) => {
                        return h(
                            "span",
                            params.row.sjpgsl ? Number(params.row.sjpgsl).toFixed(2) : params.row.sjpgsl
                        );
                    },
                },
                {
                    title: "实际募资总额 （万元）",
                    ellipsis: true,
                    tooltip: true,

                    align: "center",
                    key: "sjmzze",
                    renderHeader: (h, index) => {
                        return h("div", [h("div", "实际募资总额"), h("div", "（万元）")]);
                    },
                    render: (h, params) => {
                        return h(
                            "span",
                            params.row.sjmzze ? Number(params.row.sjmzze).toFixed(2) : params.row.sjmzze
                        );
                    },
                },
                {
                    title: "实际募资净额 （万元）",
                    ellipsis: true,
                    tooltip: true,

                    align: "center",
                    key: "sjmzje",
                    renderHeader: (h, index) => {
                        return h("div", [h("div", "实际募资净额"), h("div", "（万元）")]);
                    },
                    render: (h, params) => {
                        return h(
                            "span",
                            params.row.sjmzje ? Number(params.row.sjmzje).toFixed(2) : params.row.sjmzje
                        );
                    },
                },
                {
                    title: "配股前总股本 （元）",
                    ellipsis: true,
                    tooltip: true,

                    align: "center",
                    key: "pgqzgb",
                    renderHeader: (h, index) => {
                        return h("div", [h("div", "配股前总股本"), h("div", "（元）")]);
                    },
                    render: (h, params) => {
                        return h(
                            "span",
                            params.row.pgqzgb ? Number(params.row.pgqzgb).toFixed(2) : params.row.pgqzgb
                        );
                    },
                },
                {
                    title: "配股后总股本 （元）",
                    ellipsis: true,
                    tooltip: true,

                    align: "center",
                    key: "pghzgb",
                    renderHeader: (h, index) => {
                        return h("div", [h("div", "配股后总股本"), h("div", "（元）")]);
                    },
                    render: (h, params) => {
                        return h(
                            "span",
                            params.row.pghzgb ? Number(params.row.pghzgb).toFixed(2) : params.row.pghzgb
                        );
                    },
                },
                {
                    title: "配股上市日",
                    ellipsis: true,
                    tooltip: true,

                    align: "center",
                    key: "pgssr",
                },
                {
                    title: "详细",
                    ellipsis: true,
                    tooltip: true,

                    align: "center",
                    slot: "action",
                    key: "detail",
                },
            ],
            stockColumns2: [{
                    title: "证券代码",
                    ellipsis: true,
                    tooltip: true,

                    align: "center",
                    key: "zqdm",
                },
                {
                    title: "证券简称",
                    ellipsis: true,
                    tooltip: true,

                    align: "center",
                    key: "zqjc",
                },
                {
                    title: "股票类别",
                    ellipsis: true,
                    tooltip: true,

                    align: "center",
                    key: "gplb",
                },
                {
                    title: "增发代码",
                    ellipsis: true,
                    tooltip: true,

                    align: "center",
                    key: "zfdm",
                },
                {
                    title: "增发简称",
                    ellipsis: true,
                    tooltip: true,

                    align: "center",
                    key: "zfjj",
                },
                {
                    title: "发行价格/元",
                    ellipsis: true,
                    tooltip: true,

                    align: "center",
                    key: "fxjg",
                    render: (h, params) => {
                        return h(
                            "span",
                            params.row.fxjg ? Number(params.row.fxjg).toFixed(2) : params.row.fxjg
                        );
                    },
                },
                {
                    title: "总发行数量",
                    ellipsis: true,
                    tooltip: true,

                    align: "center",
                    key: "fxzsl",
                    render: (h, params) => {
                        return h(
                            "span",
                            params.row.fxzsl ? Number(params.row.fxzsl).toFixed(2) : params.row.fxzsl
                        );
                    },
                },
                {
                    title: "每股面值 （元）",
                    ellipsis: true,
                    tooltip: true,

                    align: "center",
                    key: "mgmz",
                    renderHeader: (h, index) => {
                        return h("div", [h("div", "每股面值"), h("div", "（元）")]);
                    },
                    render: (h, params) => {
                        return h(
                            "span",
                            params.row.mgmz ? Number(params.row.mgmz).toFixed(2) : params.row.mgmz
                        );
                    },
                },
                {
                    title: "发行日期",
                    ellipsis: true,
                    tooltip: true,

                    align: "center",
                    key: "wsfxrq",
                },
                {
                    title: "上市公告日期",
                    ellipsis: true,
                    tooltip: true,

                    align: "center",
                    key: "ssggrq",
                },
                {
                    title: "实际募资总额 （万元）",
                    ellipsis: true,
                    tooltip: true,

                    align: "center",
                    key: "sjmzze",
                    renderHeader: (h, index) => {
                        return h("div", [h("div", "实际募资总额"), h("div", "（万元）")]);
                    },
                    render: (h, params) => {
                        return h(
                            "span",
                            params.row.sjmzze ? Number(params.row.sjmzze).toFixed(2) : params.row.sjmzze
                        );
                    },
                },
                {
                    title: "实际募资净额 （万元）",
                    ellipsis: true,
                    tooltip: true,

                    align: "center",
                    key: "zjmzje",
                    renderHeader: (h, index) => {
                        return h("div", [h("div", "实际募资净额"), h("div", "（万元）")]);
                    },
                    render: (h, params) => {
                        return h(
                            "span",
                            params.row.zjmzje ? Number(params.row.zjmzje).toFixed(2) : params.row.zjmzje
                        );
                    },
                },
                {
                    title: "详细",
                    ellipsis: true,
                    tooltip: true,

                    align: "center",
                    slot: "action",
                    key: "detail",
                },
            ],
            stockColumns1: [{
                    title: "证券代码",
                    ellipsis: true,
                    tooltip: true,

                    align: "center",
                    key: "zqdm",
                },
                {
                    title: "证券简称",
                    ellipsis: true,
                    tooltip: true,

                    align: "center",
                    key: "zqjc",
                },
                {
                    title: "股票类别",
                    ellipsis: true,
                    tooltip: true,

                    align: "center",
                    key: "gplb",
                },
                {
                    title: "总发行数量",
                    ellipsis: true,
                    tooltip: true,

                    align: "center",
                    key: "gkfxsl",
                    render: (h, params) => {
                        return h(
                            "span",
                            params.row.gkfxsl ? Number(params.row.gkfxsl).toFixed(2) : params.row.gkfxsl
                        );
                    },
                },
                {
                    title: "每股面值 （元）",
                    ellipsis: true,
                    tooltip: true,

                    renderHeader: (h, index) => {
                        return h("div", [h("div", "每股面值"), h("div", "（元）")]);
                    },
                    align: "center",
                    key: "mgmz",
                    render: (h, params) => {
                        return h(
                            "span",
                            params.row.mgmz ? Number(params.row.mgmz).toFixed(2) : params.row.mgmz
                        );
                    },
                },
                {
                    title: "发行价格 （元）",
                    ellipsis: true,
                    tooltip: true,

                    renderHeader: (h, index) => {
                        return h("div", [h("div", "发行价格"), h("div", "（元）")]);
                    },
                    align: "center",
                    key: "fxjg",
                    render: (h, params) => {
                        return h(
                            "span",
                            params.row.fxjg ? Number(params.row.fxjg).toFixed(2) : params.row.fxjg
                        );
                    },
                },
                // {
                //     title: '发行方式',
                //     align: 'center',
                //     key: 'fxfs'
                // },
                {
                    title: "实际募资总额 （万元）",
                    ellipsis: true,
                    tooltip: true,

                    align: "center",
                    key: "sjmzze",
                    renderHeader: (h, index) => {
                        return h("div", [h("div", "实际募资总额"), h("div", "（万元）")]);
                    },
                    render: (h, params) => {
                        return h(
                            "span",
                            params.row.sjmzze ? Number(params.row.sjmzze).toFixed(2) : params.row.sjmzze
                        );
                    },
                },
                {
                    title: "实际募资净额 （万元）",
                    ellipsis: true,
                    tooltip: true,

                    align: "center",
                    key: "sjmzje",
                    renderHeader: (h, index) => {
                        return h("div", [h("div", "实际募资净额"), h("div", "（万元）")]);
                    },
                    render: (h, params) => {
                        return h(
                            "span",
                            params.row.sjmzje ? Number(params.row.sjmzje).toFixed(2) : params.row.sjmzje
                        );
                    },
                },
                {
                    title: "发行日期",
                    ellipsis: true,
                    tooltip: true,

                    align: "center",
                    key: "swfxrq",
                },
                {
                    title: "上市公告日期",
                    ellipsis: true,
                    tooltip: true,

                    align: "center",
                    key: "ssggrq",
                },
                {
                    title: "详细",
                    align: "center",
                    ellipsis: true,
                    tooltip: true,

                    slot: "action",
                    key: "detail",
                },
            ],
        };
    },
    computed: {
        stockColumns() {
            switch (this.type) {
                case "startIssue":
                    return this.stockColumns1;
                    // break;
                case "addIssue":
                    return this.stockColumns2;
                    // break;
                case "rightsIssue":
                    return this.stockColumns3;
                    // break;
                default:
                    return this.stockColumns1;
            }
        },
        ...mapState(["activeDetail"]),
    },
    filters: {},
    methods: {
        typeFilter(value) {
            switch (value) {
                case "startIssue":
                    return "首发";
                case "addIssue":
                    return "增发";
                case "rightsIssue":
                    return "配股";
            }
        },
        getTimeStartfx(value) {
            this.issued.timeStart = value;
        },
        getTimeEndfx(value) {
            this.issued.timeEnd = value;
        },
        ...mapMutations(["changeState"]),
        changePage(value) {
            this.current = value;
            this.stockTable = [];
            this.search();
        },
        changeType(value) {
            // switch (value) {
            //     case 'addIssue':
            //         this.$btnlog(16);
            //         break;
            //     case 'startIssue':
            //         this.$btnlog(17);
            //         break;
            //     case 'rightsIssue':
            //         this.$btnlog(18);
            //         break;
            // }
            this.stockTable = [];
            this.current = 0;
            this.search();
        },
        //查找
        search() {
            this.dataLoading = true;
            let data = {
                current: this.current,
                size: 10,
                fxrq: this.issued.timeStart && this.issued.timeEnd ?
                    [this.issued.timeStart, this.issued.timeEnd] :
                    [],
                ssrq: this.goPublic.timeStart && this.goPublic.timeEnd ?
                    [this.goPublic.timeStart, this.goPublic.timeEnd] :
                    [],
                zqdm: this.code,
                zqjc: this.name,
            };
            if (data.fxrq.length == 2) {
                if (!checkDate(data.fxrq[0], data.fxrq[1])) {
                    this.dataLoading = false;
                    this.$msg.error({
                        type: "remind",
                        code: 4015
                    });

                    return;
                }
            }
            if (data.ssrq.length == 2) {
                if (!checkDate(data.ssrq[0], data.ssrq[1])) {
                    this.dataLoading = false;
                    this.$msg.error({
                        type: "remind",
                        code: 4015
                    });

                    return;
                }
            }
            switch (this.type) {
                case "startIssue":
                    getStartData(data)
                        .then((info) => {
                            this.stockTable = info.records;
                            this.totalNum = info.total;
                        })
                        .finally(() => {
                            this.dataLoading = false;
                        });
                    break;
                case "addIssue":
                    // Object.assign(data, { zffs: this.addIssueType == 0 ? '' : this.addIssueType })
                    getAddData(data)
                        .then((info) => {
                            this.stockTable = info.records;
                            this.totalNum = info.total;
                        })
                        .finally(() => {
                            this.dataLoading = false;
                        });
                    break;
                case "rightsIssue":
                    getMatchData(data)
                        .then((info) => {
                            this.stockTable = info.records;
                            this.totalNum = info.total;
                        })
                        .finally(() => {
                            this.dataLoading = false;
                        });
                    break;
            }
        },
        showDetail(row) {
            switch (this.type) {
                case "startIssue":
                    startDetail({
                        id: row.id,
                    }).then((info) => {
                        const title = "新股";
                        info.gptitle = title;
                        this.changeState({
                            prop: "activeDetail",
                            value: info,
                        });
                        localStorage.setItem("activeDetail", JSON.stringify(info));
                        // this.$router.push({
                        //     path: `/Insight/financialMarket/financialProduct/stockDetail`
                        // });

                        let routeData = this.$router.resolve({
                            path: "/Insight/financialMarket/financialProduct/stockDetail",
                        });
                        window.open(routeData.href, "_blank");
                    });
                    break;
                case "addIssue":
                    addDetail({
                        id: row.id,
                    }).then((info) => {
                        const title = "增发";
                        info.gptitle = title;
                        this.changeState({
                            prop: "activeDetail",
                            value: info,
                        });
                        localStorage.setItem("activeDetail", JSON.stringify(info));
                        // this.$router.push({
                        //     path: `/Insight/financialMarket/financialProduct/stockDetail`
                        // });
                        let routeData = this.$router.resolve({
                            path: "/Insight/financialMarket/financialProduct/stockDetail",
                        });
                        window.open(routeData.href, "_blank");
                    });
                    break;
                case "rightsIssue":
                    matchDetail({
                        id: row.id,
                    }).then((info) => {
                        const title = "配股";
                        info.gptitle = title;
                        this.changeState({
                            prop: "activeDetail",
                            value: info,
                        });
                        localStorage.setItem("activeDetail", JSON.stringify(info));
                        // this.$router.push({
                        //     path: `/Insight/financialMarket/financialProduct/stockDetail`
                        // });
                        let routeData = this.$router.resolve({
                            path: "/Insight/financialMarket/financialProduct/stockDetail",
                        });
                        window.open(routeData.href, "_blank");
                    });
                    break;
            }
        },
        getTimeStart(value) {
            this.goPublic.timeStart = value;
        },
        getTimeEnd(value) {
            this.goPublic.timeEnd = value;
        },
        //清空筛选
        clear(){
          this.code = ''
          this.name = ''
          this.issued = {
                timeStart: "",
                timeEnd: "",
            }
             this.goPublic = {
                timeStart: "",
                timeEnd: "",
            }
        }
    },
    beforeMount() {
        this.$Notice.destroy();
    },
    mounted() {
        this.search();



    },
};
</script>

<style lang="scss" scoped>
@import "@/style/common.scss";

.stock {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: #fff;
    padding: 20px;
}

.stock {
    .topPart {
        width: 100%;
        height: 239px;
        background: rgba(255, 255, 255, 1);
        border-radius: 10px;
        border: 1px solid rgba(227, 227, 229, 1);
        padding: 20px 30px;

        .form-item {
            margin-bottom: 20px;
            @include flex(flex-start);

            .title {
                font-size: 14px;
                font-family: MicrosoftYaHei;
                color: rgba(102, 102, 102, 1);
                line-height: 19px;
                margin-right: 20px;
            }

            .addIssue {
                position: relative;

                .seletcTypeBox {
                    position: absolute;
                    left: -5px;
                    top: 20px;
                    height: 40px;
                    display: none;
                    background: #fff;
                    border: 1px solid rgba(227, 227, 229, 1);
                    padding: 10px;
                    z-index: 100;
                }

                &:hover {
                    .seletcTypeBox {
                        display: block;
                    }
                }
            }
        }
    }

    .middlePart {
        margin: 20px 0;

        .tabContent {
            color: #00b6ff;
        }
    }
}
</style>
